import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Tower Of Hanoi | Code Tutorial'}
			subHeader={''}
			description={'The Tower of Hanoi (also called the Tower of Brahma or Lucas\' Tower and sometimes pluralized) is a mathematical game or puzzle. It consists of three rods and a number of disks of different sizes, which can slide onto any rod. The puzzle starts with the disks in a neat stack in ascending order of size on one rod, the smallest at the top, thus making a conical shape.'}
			keywords={['Tower Of Hanoi Code']}
			embeddedVideo={'daFzJOw142o'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/baae38356df4ccfef7fbb81ab16f17ee/raw/130aca9365f827732880e718367ab32087f64d21/tower-of-hanoi-code.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
